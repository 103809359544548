import axios from "axios";
import jwtDefaultConfig from "./jwtDefaultConfig";

export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig };

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false;

  // ** For Refreshing Token
  subscribers = [];

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig };

    // ** Request Interceptor
    axios.interceptors.request.use(
      (config) => {
        // ** Get token from localStorage
        const accessToken = this.getToken();
        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
          // ** eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    // ** Add request/response interceptor
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        // ** const { config, response: { status } } = error
        const { config, response } = error;
        const originalRequest = config;

        // ** if (status === 401) {
        if (response && response.status === 401) {
          console.log("Enter in 401");
          // localStorage.clear();
          // window.location.href = '/login';
        }
        return Promise.reject(error);
      }
    );
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) =>
      callback(accessToken)
    );
  }

  addSubscriber(callback) {
    this.subscribers.push(callback);
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName);
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName);
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value);
  }

  addStore(...args) {
    return axios.post(this.jwtConfig.addStore, ...args);
  }
  updateStore(args) {
    const timeZone = {timezone_id : args.values}
    return axios.put(this.jwtConfig.updateStore + `/${args.id}`, timeZone);
  }
  deleteStaff(...args) {
    const [id] = [...args]
    return axios.delete(`${this.jwtConfig.deleteStaff}/${id}`, ...args)
  }
  storeList(...args) {
    const id = [...args]
    return axios.get(`${this.jwtConfig.listStore}/${id}`);
  }

  trackOrder(...args) {
    const id = [...args];
    return axios.get(`${this.jwtConfig.trackOrder}/${id}`);
  }

  storeStaff(...args) {
    return axios.post(this.jwtConfig.staffStore, ...args);
  }

  listStaff(...args) {
    const id = [...args]
    return axios.get(`${this.jwtConfig.listStaff}/${id}`);
  }

  staffUpdate(...args) {
    const id = [...args];
    return axios.post(`${this.jwtConfig.staffUpdate}/${id[0]}`, id[1]);
  }

  showStaff(...args) {
    const id = [...args];
    return axios.get(`${this.jwtConfig.showStaff}/${id}`);
  }

  listCountry(...args) {
    return axios.get(this.jwtConfig.countryList, ...args);
  }

  getPlans(...args) {
    const id = [...args]
    return axios.get(`${this.jwtConfig.getPlans}${id}`);
  }
  deleteIncompleteSuscription(...args) {
    return axios.post(this.jwtConfig.deleteIncompleteSuscription, ...args)
  }
  showTrackOrder(...args) {
    const id = [...args];
    return axios.get(`${this.jwtConfig.showTrackOrderList}/${id}`);
  }

  storeAnalytics(...args) {
    const id = [...args];
    return axios.get(`${this.jwtConfig.storeAnalytics}/${id}`);
  }
  storeDelete(...args) {
    const id = [...args];
    return axios.delete(`${this.jwtConfig.storeDelete}/${id}`);
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value);
  }

  login(...args) {
    return axios.post(this.jwtConfig.loginEndpoint, ...args);
  }

  register(...args) {
    return axios.post(this.jwtConfig.registerEndpoint, ...args);
  }

  getAdminDashBoard(...args) {
    const id = [...args]
    return axios.get(`${this.jwtConfig.getAdminDashBoard}/${id}`);
  }

  subscribePlan(...args) {
    return axios.post(this.jwtConfig.subscribePlan, ...args);
  }
  cancelPlan(...args) {
    return axios.post(this.jwtConfig.cancelPlan, ...args);
  }
  reactivatePlan(...args) {
    return axios.post(this.jwtConfig.reactivatePlan, ...args);
  }
  upgradePlan(...args) {
    return axios.post(this.jwtConfig.upgradePlan, ...args);
  }
  getAdminInvoiceData(...args) {
    const id = [...args]
    return axios.get(`${this.jwtConfig.getAdminInvoiceData}/${id}`)
  }
  getPlanInvoiceData(...args) {
    const id = [...args]
    return axios.get(`${this.jwtConfig.getPlanInvoiceData}/${id}`)
  }
}
