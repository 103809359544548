import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import jwtDefaultConfig from "../@core/auth/jwt/jwtDefaultConfig";


export const getAdminPlanUnlimitedCustom = createAsyncThunk('getAdminPlanUnlimited', async (data) => {
    try {
        const res = await axios(jwtDefaultConfig.userUpdatePlan , {
            method: 'POST',
            data: data,
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return res.data
    }
    catch (error) {
        if (error.response) {
            return error.response.data

        }
    }

})

const getAdminPlanUnlimitedMethod = createSlice({
    name: 'adminPlanUnlimited',
    initialState: {
        loading: false,
        AdminPlanUnlimited: []
    },
    reducers: {
        getAdminPlanUnlimited(state, action) {
            state = action.payload
        }
    },
    extraReducers: {
        [getAdminPlanUnlimitedCustom.fulfilled]: (state, action) => {
            state.AdminPlanUnlimited = action.payload
            state.loading = false
        },
        [getAdminPlanUnlimitedCustom.pending]: (state, action) => {
            state.loading = true;
        },
        [getAdminPlanUnlimitedCustom.rejected]: (state, action) => {
            state.loading = false
        }
    }
})

export const { getAdminPlanUnlimited } = getAdminPlanUnlimitedMethod.actions
export default getAdminPlanUnlimitedMethod.reducer
