import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import jwtDefaultConfig from "../@core/auth/jwt/jwtDefaultConfig";

const initialData = () => {
    const addCardTokan = {}
    return addCardTokan
}

export const addCardCustom = createAsyncThunk('addCard', async (data) => {
    try {
        const res = await axios(jwtDefaultConfig.addCard, {
            method: 'POST',
            data:{stripe_token:data},
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return res.data
    }
    catch (error) {
        if (error.response) {
            return error.response.data
        }
    }

})

const addCardMethod = createSlice({
    name: 'addCardData',
    initialState: {
        loading: true,
        addCardTokan: initialData()
    },
    reducers: {
        addCard(state, action) {
            state = action.payload
        }
    },
    extraReducers: {
        [addCardCustom.fulfilled]: (state, action) => {
            state.addCardTokan = action.payload
            state.loading = false
        },
        [addCardCustom.pending]: (state, action) => {
            state.loading = true;
        },
        [addCardCustom.rejected]: (state, action) => {
            state.loading = false
        }
    }
})

export const { addCard } = addCardMethod.actions
export default addCardMethod.reducer

