import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import jwtDefaultConfig from "../@core/auth/jwt/jwtDefaultConfig";


export const createAdminPlanCustom = createAsyncThunk('createAdminPlan', async (data) => {
    try {
        if (data.method.toLowerCase() === 'create') {
            const res = await axios(jwtDefaultConfig.createAdminPlan, {
                method: 'POST',
                data: data.data,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            return res.data
        }
        if (data.method.toLowerCase() === 'update') {
            const res = await axios(jwtDefaultConfig.updateAdminPlan + `/${data.id}`, {
                method: 'PUT',
                data: data.data,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            return res.data
        }

    }
    catch (error) {
        if (error.response) {
            return error.response.data
        }
    }

})

export const deleteAdminPlanCustom = createAsyncThunk('deleteAdminPlan', async (data) => {
    try {
        const res = await axios.delete(jwtDefaultConfig.deleteAdminPlan + `/${data.id}`)
        return res.data
    }
    catch (error) {
        if (error.response) {
            return error.response.data

        }
    }

})

const createAdminPlanMethod = createSlice({
    name: 'adminPlanData',
    initialState: {
        loading: false,
        adminPlanCreate: {}
    },
    reducers: {
        createAdminPlan(state, action) {
            state = action.payload
        }
    },
    extraReducers: {
        [createAdminPlanCustom.fulfilled]: (state, action) => {
            state.adminPlanCreate = action.payload
            state.loading = false
        },
        [createAdminPlanCustom.pending]: (state, action) => {
            state.loading = true;
        },
        [createAdminPlanCustom.rejected]: (state, action) => {
            state.adminPlanCreate = action.payload
            state.loading = false
        },
        [deleteAdminPlanCustom.fulfilled]: (state, action) => {
            state.adminPlanCreate = action.payload
            state.loading = false
        },
        [deleteAdminPlanCustom.pending]: (state, action) => {
            state.loading = true;
        },
        [deleteAdminPlanCustom.rejected]: (state, action) => {
            state.adminPlanCreate = action.payload
            state.loading = false
        }
    },

})

export const { createAdminPlan } = createAdminPlanMethod.actions
export default createAdminPlanMethod.reducer

