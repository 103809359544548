// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";
import useJwt from "@src/auth/jwt/useJwt";
import storeList from "./storeList";
 
export const getStoreList = createAsyncThunk(
  "dashboard/getStoreList",
  async (args,{dispatch}) => { 
      const response = await useJwt.getAdminDashBoard(args); 
      return {
        adminData: response?.data?.data,
        paginate:response?.data?.data?.users
      }  
    }
    );
    
    export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    adminData: {},
    paginate:{}
  },
  reducers: {
    _paginate:(state , action)=>{
      state.paginate=action.payload  
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getStoreList.fulfilled, (state, action) => {
       state.adminData = action.payload.adminData;
      state.paginate = action.payload.paginate
    });
  },
});

export const { _paginate } = dashboardSlice.actions;

export default dashboardSlice.reducer;
