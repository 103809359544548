// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import useJwt from "@src/auth/jwt/useJwt";

export const getPlans = createAsyncThunk("plans/getPlans", async (args) => {
  const res = await useJwt.getPlans(args);
  if (res.data.error) {
    console.log("Error");
  } else {
    let data = res?.data?.data;
    var plansData = data;
    var paginate = data?.subscription_history;
   
  }
  return {
    plansData,
    paginate,
  };
});
export const planSlice = createSlice({
  name: "plans",
  initialState: {
    plansData: {},
    paginate: {},
    loading:false
  },
  reducers: {
    _paginate: (state, action) => {
      state.paginate = action.payload;
    },
    _loading:(state, action)=>{
      state.loading = action.payload
    }
  },
  extraReducers: {
    [getPlans.pending]: (state, action) => {
      state._loading = true
      state.plansData = action.payload.plansData;
      state.paginate = action.payload.paginate;
     },
    [getPlans.fulfilled]: (state, action) => {
      state.loading = false
      state.plansData = action.payload.plansData;
      state.paginate = action.payload.paginate;
     },
     [getPlans.rejected]: (state, action) => {
       state.loading = false
       state.plansData ={};
       state.paginate ={};
     },
  },
});

export const { _paginate } = planSlice.actions;

export default planSlice.reducer;
