import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import jwtDefaultConfig from "../@core/auth/jwt/jwtDefaultConfig";

const initialData = () => {
    const data = {}
    return data
}

export const getUserList = createAsyncThunk('getUserList', async () => {
    const res = await axios.get(jwtDefaultConfig.profileData)
    return res.data.data
})

const userListReducer = createSlice({
    name: 'profileData',
    initialState: {
        loading: null,
        data: initialData()
    },
    reducers: {
        getData(state, action) {
            state = action.payload
        }
    },
    extraReducers: {
        [getUserList.fulfilled]: (state, action) => {
            state.data = action.payload
            state.loading = false
        },
        [getUserList.pending]: (state, action) => {
            state.loading = true;
        },
        [getUserList.rejected]: (state, action) => {
            state.loading = false
        }
    }
})

export const { getData } = userListReducer.actions
export default userListReducer.reducer

