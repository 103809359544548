import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import jwtDefaultConfig from "../@core/auth/jwt/jwtDefaultConfig";

const initialData = () => {
    const forgetPassword = {}
    return forgetPassword
}

export const forgetPasswordCustom = createAsyncThunk('forgetPassword', async (data) => {
    try {
        const res = await axios(jwtDefaultConfig.forgetPassword, {
            method: 'POST',
            data:{email:data.email},
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return res.data
    }
    catch (error) {
        if (error.response) {
            return error.response.data
        }
    }

})

const addCardMethod = createSlice({
    name: 'forgetPasswordData',
    initialState: {
        loading: true,
        forgetPassword: initialData()
    },
    reducers: {
        forgetPassCall(state, action) {
            state = action.payload
        }
    },
    extraReducers: {
        [forgetPasswordCustom.fulfilled]: (state, action) => {
            state.forgetPassword = action.payload
            state.loading = false
        },
        [forgetPasswordCustom.pending]: (state, action) => {
            state.loading = true;
        },
        [forgetPasswordCustom.rejected]: (state, action) => {
            state.loading = false
        }
    }
})

export const { forgetPassCall } = addCardMethod.actions
export default addCardMethod.reducer

