import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import jwtDefaultConfig from "../@core/auth/jwt/jwtDefaultConfig";


export const getAdminPlanHistroyCustom = createAsyncThunk('getAdminPlanHistory', async ({state,page,perPageData}) => {
    try {
        const res = await axios.get(jwtDefaultConfig.subscriptionHistory + `/${state}?per_page=${perPageData}&page=${page}`)
        return res.data.data
    }
    catch (error) {
        if (error.response) {
            return error.response.data

        }
    }

})

const getAdminPlanHistoryMethod = createSlice({
    name: 'adminPlanHistoryList',
    initialState: {
        loading: false,
        listAdminPlanHistory: []
    },
    reducers: {
        getAdminPlanHistory(state, action) {
            state = action.payload
        }
    },
    extraReducers: {
        [getAdminPlanHistroyCustom.fulfilled]: (state, action) => {
            state.listAdminPlanHistory = action.payload
            state.loading = false
        },
        [getAdminPlanHistroyCustom.pending]: (state, action) => {
            state.loading = true;
        },
        [getAdminPlanHistroyCustom.rejected]: (state, action) => {
            state.loading = false
        }
    }
})

export const { getAdminPlanHistory } = getAdminPlanHistoryMethod.actions
export default getAdminPlanHistoryMethod.reducer
