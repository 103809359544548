// ** Auth Endpoints
const url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "https://etrackmanager-api.lusites.xyz/api"
// const url = process.env.REACT_APP_BASE_URL_DEV ? process.env.REACT_APP_BASE_URL_DEV : "https://etrackmanager-api.lusites.xyz/api"
export default {
  loginEndpoint: `${url}/login`,
  registerEndpoint: `${url}/register`,
  addStore: `${url}/add-store`,
  listStore: `${url}/store-list`,
  trackOrder: `${url}/track-orders`,
  staffStore: `${url}/add-staff`,
  listStaff: `${url}/staff-list`,
  countryList: `${url}/init`,
  showTrackOrderList: `${url}/show-track-order`,
  staffUpdate: `${url}/update-staff`,
  showStaff: `${url}/staff-show`,
  storeAnalytics: `${url}/analytics`,
  storeDelete: `${url}/delete`,
  getPlans: `${url}/get-plans`,
  getAdminDashBoard: `${url}/admin-dashboard`,
  refreshEndpoint: '/refresh-token',
  logoutEndpoint: '/logout',
  subscribePlan: `${url}/subscribe-plan`,
  cancelPlan: `${url}/cancel-plan`,
  reactivatePlan: `${url}/reactivate-plan`,
  upgradePlan: `${url}/change-plan`,
  deleteIncompleteSuscription: `${url}/delete-incomplete-subscription`,
  deleteStaff: `${url}/delete-staff`,
  profileData: `${url}/user-profile`,
  changePassword: `${url}/change-password`,
  addCard: `${url}/add-card`,
  cardList: `${url}/card-list`,
  handleStripeCard: `${url}/handle-stripe-card `,
  forgetPassword: `${url}/forget-password`,
  resetPassword: `${url}/reset-password`,
  getAdminInvoiceData: `${url}/user-detail`,
  getPlanInvoiceData: `${url}/subscription-invoice`,
  emailVerified: `${url}/email/verify`,
  emailResend: `${url}/email/resend`,
  createAdminPlan: `${url}/admin/plans/create`,
  getAdminPlan: `${url}/admin/plans/get?sort_field=id&sort_order=desc`,
  updateAdminPlan: `${url}/admin/plans/update`,
  deleteAdminPlan: `${url}/admin/plans/delete`,
  updateStore: `${url}/update-store`,
  subscriptionHistory: `${url}/subscription-history`,
   userUpdatePlan :`${url}/admin/update-user`,
  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'tokenName',
  storageRefreshTokenKeyName: 'token',
}
