import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import jwtDefaultConfig from "../@core/auth/jwt/jwtDefaultConfig";


export const resedEmailCustom = createAsyncThunk('resend', async (data) => {
    try {
        const tokenStr = localStorage.getItem('token')
        const res = await axios.get(jwtDefaultConfig.emailResend, {
            headers: {
                "Authorization": `Bearer ${tokenStr}`
            }
        })
         
        return res.data;
    }
    catch (error) {
        if (error.response) {
          
            return error.response.data
        }
    }

})

const resendMethod = createSlice({
    name: 'resendData',
    initialState: {
        loading: false,
        resendEmailData: {}
    },
    reducers: {
        resendEmail(state, action) {
            state = action.payload
        }
    },
    extraReducers: {
        [resedEmailCustom.fulfilled]: (state, action) => {
            state.resendEmailData = action.payload
            state.loading = false
        },
        [resedEmailCustom.pending]: (state, action) => {
            state.loading = true;
        },
        [resedEmailCustom.rejected]: (state, action) => {
            state.loading = false
        }
    }
})

export const { resendEmail } = resendMethod.actions
export default resendMethod.reducer

