import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import jwtDefaultConfig from "../@core/auth/jwt/jwtDefaultConfig";

const initialData = () => {
    const ChangePasswordData = {}
    return ChangePasswordData
}

export const ChangePasswordCustom = createAsyncThunk('ChangePassword', async (data) => {
    try {
        const res = await axios(jwtDefaultConfig.changePassword, {
            method: 'POST',
            data: data,
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return res.data
    }
    catch (error) {
        if (error.response) {
            return error.response.data
        }
    }

})

const ChangePasswordReducer = createSlice({
    name: 'changePassword',
    initialState: {
        loading: null,
        changePasswordData: initialData()
    },
    reducers: {
        getChangePassword(state, action) {
            state = action.payload
        }
    },
    extraReducers: {
        [ChangePasswordCustom.fulfilled]: (state, action) => {
            state.changePasswordData = action.payload
            state.loading = false
        },
        [ChangePasswordCustom.pending]: (state, action) => {
            state.loading = true;
        },
        [ChangePasswordCustom.rejected]: (state, action) => {
            state.loading = false
        }
    }
})

export const { getChangePassword } = ChangePasswordReducer.actions
export default ChangePasswordReducer.reducer

