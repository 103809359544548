// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

const initialUser = () => {
    const item = window.localStorage.getItem('storeList')
    //** Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : {}
}
export const storeListSlice = createSlice({
    name: 'storeData',
    initialState: {
        userData: initialUser()
    },
    reducers: {
        storeList: (state, action) => {
            state.userData = action.payload
            localStorage.setItem('storeList', JSON.stringify(action.payload))
        }
    }
})

export const { storeList } = storeListSlice.actions

export default storeListSlice.reducer
