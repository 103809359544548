import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import jwtDefaultConfig from "../@core/auth/jwt/jwtDefaultConfig";

const initialData = () => {
    const deleteCardData = {}
    return deleteCardData
}

export const deteleCardCustom = createAsyncThunk('deleteCard', async (cardid) => {
      try {
        const res = await axios(jwtDefaultConfig.handleStripeCard, {
            method: 'POST',
            data:cardid,
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return res.data
    }
    catch (error) {
        if (error.response) {
            return error.response.data
        }
    }

})

const deleteCardMethod = createSlice({
    name: 'deleteCard',
    initialState: {
        loading: true,
        deleteCardData: initialData()
    },
    reducers: {
        deleteCard(state, action) {
            state = action.payload
        }
    },
    extraReducers: {
        [deteleCardCustom.fulfilled]: (state, action) => {
            state.deleteCardData = action.payload
            state.loading = false
        },
        [deteleCardCustom.pending]: (state, action) => {
            state.loading = true;
        },
        [deteleCardCustom.rejected]: (state, action) => {
            state.loading = false
        }
    }
})

export const { deleteCard } = deleteCardMethod.actions
export default deleteCardMethod.reducer

