import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import jwtDefaultConfig from "../@core/auth/jwt/jwtDefaultConfig";


export const getAdminPlanCustom = createAsyncThunk('getAdminPlan', async () => {
    try {
        const res = await axios.get(jwtDefaultConfig.getAdminPlan)
        return res.data.data
    }
    catch (error) {
        if (error.response) {
            return error.response.data

        }
    }

})

const getAdminPlanMethod = createSlice({
    name: 'adminPlanList',
    initialState: {
        loadingList: false,
        listAdminPlan: {}
    },
    reducers: {
        getAdminPlan(state, action) {
            state = action.payload
        }
    },
    extraReducers: {
        [getAdminPlanCustom.fulfilled]: (state, action) => {
            state.listAdminPlan = action.payload
            state.loading = false
        },
        [getAdminPlanCustom.pending]: (state, action) => {
            state.loading = true;
        },
        [getAdminPlanCustom.rejected]: (state, action) => {
            state.loading = false
        }
    }
})

export const { getAdminPlan } = getAdminPlanMethod.actions
export default getAdminPlanMethod.reducer

