// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import profileData from './profileDataGet'
import storeList from './storeList'
import addCardData from './addCard'
import cardList from './cardList'
import deleteCard from './deleteCard'
import changePassword from './changePassword'
import todo from '@src/views/apps/todo/store'
import chat from '@src/views/apps/chat/store'
import users from '@src/views/apps/user/store'
import email from '@src/views/apps/email/store'
import kanban from '@src/views/apps/kanban/store'
import invoice from '@src/views/apps/invoice/store'
import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/store'
import dashboard from './dashboard'
import forgetPasswordData from './forgetPassRedux'
import resetPasswordData from './resetPassRedux'
import Plans from './Plans'
import resendData from './resentEmail'
import adminPlanData from './createAdminPlan'
import adminPlanList from './getAdminPlan'
import adminPlanHistoryList from './adminPLanHistoryRedux'
import adminPlanUnlimited from './adminUnlimitedPlan'

const rootReducer = {
  auth,
  todo,
  chat,
  email,
  users,
  kanban,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  permissions,
  dashboard,
  storeList,
  profileData,
  changePassword,
  addCardData,
  cardList,
  deleteCard,
  forgetPasswordData,
  resetPasswordData,
  Plans,
  resendData,
  adminPlanData,
  adminPlanList,
  adminPlanHistoryList,
  adminPlanUnlimited
}

export default rootReducer
