import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import jwtDefaultConfig from "../@core/auth/jwt/jwtDefaultConfig";

const initialData = () => {
    const cardListData = {}
    return cardListData
}

export const cardListCustom = createAsyncThunk('cardList', async (data) => {
    try {
        const res = await axios.get(jwtDefaultConfig.cardList)
        return res.data.data
    }
    catch (error) {
        if (error.response) {
            return error.response.data
        }
    }

})

const cardListMethod = createSlice({
    name: 'cardList',
    initialState: {
        loading: true,
        cardListData: initialData()
    },
    reducers: {
        cardList(state, action) {
            state = action.payload
        }
    },
    extraReducers: {
        [cardListCustom.fulfilled]: (state, action) => {
            state.cardListData = action.payload
            state.loading = false
        },
        [cardListCustom.pending]: (state, action) => {
            state.loading = true;
        },
        [cardListCustom.rejected]: (state, action) => {
            state.loading = false
        }
    }
})

export const { cardList } = cardListMethod.actions
export default cardListMethod.reducer

