import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import jwtDefaultConfig from "../@core/auth/jwt/jwtDefaultConfig";

const initialData = () => {
    const resetPassword = {}
    return resetPassword
}

export const resetPasswordCustom = createAsyncThunk('resetPassword', async (data) => {
    try {
        const res = await axios(jwtDefaultConfig.resetPassword, {
            method: 'POST',
            data:{email:data.email,password:data.password,password_confirmation:data.password_confirmation,token:data.token},
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return res.data
    }
    catch (error) {
        if (error.response) {
            return error.response.data
        }
    }

})

const addCardMethod = createSlice({
    name: 'resetPasswordData',
    initialState: {
        loading: true,
        resetPassword: initialData()
    },
    reducers: {
        resetPassCall(state, action) {
            state = action.payload
        }
    },
    extraReducers: {
        [resetPasswordCustom.fulfilled]: (state, action) => {
            state.resetPassword = action.payload
            state.loading = false
        },
        [resetPasswordCustom.pending]: (state, action) => {
            state.loading = true;
        },
        [resetPasswordCustom.rejected]: (state, action) => {
            state.loading = false
        }
    }
})

export const { resetPassCall } = addCardMethod.actions
export default addCardMethod.reducer

